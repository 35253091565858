import type { LinkProps } from "@remix-run/react";
import type { ClassValue } from "clsx";
import type {
  ReactNode,
  ButtonHTMLAttributes,
  MouseEventHandler,
  AnchorHTMLAttributes,
} from "react";

import { Link as RemixLink } from "@remix-run/react";
import { clsx } from "clsx";

import { cn } from "~/utils/std";

type Size = "xl" | "lg" | "base" | "sm" | "xs";

type Color = "primary" | "white" | "danger" | "success";

export type Status = "idle" | "pending" | "disabled";

export type ButtonProps = {
  size?: Size;
  color: Color;
  children: ReactNode;
  icon?: ReactNode;
  className?: ClassValue;
  status?: Status;
};

type SubmitButtonProps = ButtonProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, "className" | "style" | "type">;

type CustomButtonProps = Omit<SubmitButtonProps, "onClick"> & {
  onClick: MouseEventHandler<HTMLButtonElement>;
};

type LinkButtonProps = ButtonProps & Omit<LinkProps, "className" | "style">;

type AnchorButtonProps = ButtonProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "className" | "style" | "type">;

const SIZE_STYLES: { [Key in Size]: ClassValue } = {
  xl: "text-base leading-6 font-medium pt-3 pb-3.5 px-6",
  lg: "text-base leading-6 font-medium pt-2 pb-2.5 px-4",
  base: "text-sm leading-5 font-medium pt-2 pb-2.5 px-4",
  sm: "text-sm leading-4 font-medium pt-2 pb-2.5 px-4",
  xs: "text-xs leading-4 font-medium pt-1.5 pb-2 px-3",
};

const COLOR_STYLES: { [Key in Color]: ClassValue } = {
  primary:
    "text-white bg-neutral-900 border border-neutral-900 hover:bg-neutral-800 hover:border-neutral-800 focus:border-neutral-900 focus:bg-neutral-900",
  success:
    "text-white bg-accentTeal-700 border border-accentTeal-700 hover:bg-accentTeal-600 hover:border-accentTeal-600 focus:border-accentTeal-700 focus:bg-accentTeal-700",
  white:
    "text-neutral-800 bg-white border border-neutral-400 hover:bg-neutral-50 focus:bg-white shadow-sm",
  danger: "text-white bg-failure-700 border border-failure-700",
};

export namespace Button {
  export function Submit({
    size = "base",
    icon,
    color,
    children,
    className,
    status = "idle",
    ...props
  }: SubmitButtonProps) {
    let hasIcon = Boolean(icon);
    return (
      <button
        type="submit"
        {...props}
        className={cn(
          "relative text-center rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accentTeal-600 whitespace-nowrap",
          SIZE_STYLES[size],
          COLOR_STYLES[color],
          className,
        )}
      >
        <div
          className={clsx({
            "flex items-center gap-2 justify-center": hasIcon,
          })}
        >
          {children}
          {hasIcon && <span>{icon}</span>}
        </div>
      </button>
    );
  }

  export function Custom({
    size = "base",
    icon,
    color,
    children,
    className,
    status = "idle",
    ...props
  }: CustomButtonProps) {
    let hasIcon = Boolean(icon);
    return (
      <button
        type="button"
        {...props}
        className={cn(
          "relative text-center rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accentTeal-600 whitespace-nowrap",
          SIZE_STYLES[size],
          COLOR_STYLES[color],
          className,
        )}
      >
        <div
          className={clsx({
            "flex items-center gap-2 justify-center": hasIcon,
          })}
        >
          {children}
          {hasIcon && <span>{icon}</span>}
        </div>
      </button>
    );
  }

  export function Link({
    size = "base",
    icon,
    color,
    children,
    className,
    status = "idle",
    ...props
  }: LinkButtonProps) {
    let hasIcon = Boolean(icon);
    return (
      <RemixLink
        {...props}
        className={cn(
          "relative text-center rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accentTeal-600 whitespace-nowrap",
          SIZE_STYLES[size],
          COLOR_STYLES[color],
          className,
        )}
      >
        <div
          className={clsx({
            "flex items-center gap-2 justify-center": hasIcon,
          })}
        >
          {children}
          {hasIcon && <span>{icon}</span>}
        </div>
      </RemixLink>
    );
  }

  export function Anchor({
    size = "base",
    icon,
    color,
    children,
    className,
    status = "idle",
    ...props
  }: AnchorButtonProps) {
    let hasIcon = Boolean(icon);
    return (
      <a
        {...props}
        className={cn(
          "relative text-center rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accentTeal-600 whitespace-nowrap",
          SIZE_STYLES[size],
          COLOR_STYLES[color],
          className,
        )}
      >
        <div
          className={clsx({
            "flex items-center gap-2 justify-center": hasIcon,
          })}
        >
          {children}
          {hasIcon && <span>{icon}</span>}
        </div>
      </a>
    );
  }
}
